import React from "react"
import {
  Box,
  Container,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  Typography,
  styled,
  Stack,
} from "@mui/material"

//@ts-ignore
import fb from "../../resources/icons/fb.svg"
//@ts-ignore
import twitter from "../../resources/icons/twitter.svg"
//@ts-ignore
import insta from "../../resources/icons/insta.svg"
//@ts-ignore
import linkedin from "../../resources/icons/link.svg"

import QueryForm from "../QueryForm"

const FooterListItemButton = styled(ListItemButton)({
  padding: 0,
  "&:hover": {
    backgroundColor: "transparent",
  },
})

const FooterListItemText = styled(ListItemText)({
  color: "#FFFFFF !important",
  textTransform: "capitalize",
  "& .MuiListItemText-primary": {
    fontSize: "0.8rem", // Set font size for primary text
  },
})

const dataJobSeeker = [
  {
    label: "find a job",
    url: "#",
  },
  {
    label: "submit your cv",
    url: "#",
  },
  {
    label: "areas of expertise",
    url: "#",
  },
  {
    label: "career advice",
    url: "#",
  },
]

const dataLocation = [
  { label: "jobs in bangalore", url: "#" },
  { label: "jobs in chennai", url: "#" },
  { label: "jobs in coimbatore", url: "#" },
  { label: "jobs in gurgaon", url: "#" },
  { label: "jobs in hyderabad", url: "#" },
  { label: "jobs in kolkata", url: "#" },
  { label: "jobs in mumbai", url: "#" },
  { label: "jobs in pune", url: "#" },
]

const jobIndustry = [
  { url: "#", label: "jobs in agro" },
  { url: "#", label: "jobs in banking & finance" },
  { url: "#", label: "jobs in construction" },
  { url: "#", label: "jobs in consulting" },
  { url: "#", label: "jobs in digital marketing" },
  { url: "#", label: "jobs in education" },
  { url: "#", label: "jobs in electronics" },
  { url: "#", label: "jobs in fmcg" },
  { url: "#", label: "jobs in healthcare" },
  { url: "#", label: "jobs in HR" },
  { url: "#", label: "jobs in IT" },
  { url: "#", label: "jobs in logistics" },
  { url: "#", label: "jobs in manufacturing" },
  { url: "#", label: "jobs in oil & gas" },
  { url: "#", label: "jobs in retail" },
  { url: "#", label: "jobs in sales" },
  { url: "#", label: "jobs in telecom" },
]

const dataEmployer = [
  { label: "submit a job", url: "#" },
  { label: "request a call back", url: "#" },
  { label: "areas of expertise", url: "#" },
  { label: "our solutions", url: "#" },
  { label: "HR news", url: "#" },
]

const dataSolutions = [
  { label: "permanent recruitment", url: "#" },
  { label: "recruitment process outsourcing", url: "#" },
  { label: "general staffing", url: "#" },
  { label: "specialty staffing", url: "#" },
  { label: "executive search", url: "#" },
  { label: "headhunting", url: "#" },
  { label: "manpower consultancy", url: "#" },
  { label: "talent acquisition", url: "#" },
  { label: "payroll transfer services", url: "#" },
  { label: "sales and trade marketing", url: "#" },
]

const dataAboutUs = [
  { label: "our history", url: "#" },
  { label: "social responsibility", url: "#" },
  { label: "women at work", url: "#" },
  { label: "press releases", url: "#" },
  { label: "Our offices", url: "#" },
]

const dataExpertize = [
  { label: "analytics and data sciences", url: "#" },
  { label: "banking and financial services", url: "#" },
  { label: "civil and architecture", url: "#" },
  { label: "logistics and supply chain", url: "#" },
  { label: "sales and account management", url: "#" },
  { label: "pharma and healthcare", url: "#" },
  { label: "support functions", url: "#" },
  { label: "human resource", url: "#" },
  { label: "construction recruitment", url: "#" },
  { label: "manufacturing staffing", url: "#" },
  { label: "fmcg recruitment", url: "#" },
  { label: "bpo recruitment", url: "#" },
]

const Footer = () => {
  return (
    <>
      <QueryForm />
      <Box width="100%" bgcolor="#00411F">
        <Container
          maxWidth="xl"
          sx={{ padding: "3em 0px 15px", color: "#FFFFFF" }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <Typography
                variant="h2"
                sx={{
                  color: "#FFFFFF",
                  fontSize: "16px",
                  fontWeight: "600",
                }}
              >
                Job seeker
              </Typography>
              <List dense>
                {dataJobSeeker.map((data, i) => (
                  <FooterListItemButton
                    key={`jobseeker-${i}`}
                    component="a"
                    href={data.url}
                  >
                    <FooterListItemText primary={data.label} />
                  </FooterListItemButton>
                ))}
              </List>
              <Typography
                variant="h2"
                sx={{
                  color: "#FFFFFF",
                  fontSize: "16px",
                  fontWeight: "600",
                  marginTop: 2,
                }}
              >
                Jobs by location
              </Typography>
              <List dense>
                {dataLocation.map((data, i) => (
                  <FooterListItemButton
                    key={`location-${i}`}
                    component="a"
                    href={data.url}
                  >
                    <FooterListItemText primary={data.label} />
                  </FooterListItemButton>
                ))}
              </List>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography
                variant="h2"
                sx={{
                  color: "#FFFFFF",
                  fontSize: "16px",
                  fontWeight: "600",
                }}
              >
                Jobs by industry
              </Typography>
              <List dense>
                {jobIndustry.map((data, i) => (
                  <FooterListItemButton
                    key={`industry-${i}`}
                    component="a"
                    href={data.url}
                  >
                    <FooterListItemText primary={data.label} />
                  </FooterListItemButton>
                ))}
              </List>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography
                variant="h2"
                sx={{
                  color: "#FFFFFF",
                  fontSize: "16px",
                  fontWeight: "600",
                }}
              >
                Employer
              </Typography>
              <List dense>
                {dataEmployer.map((data, i) => (
                  <FooterListItemButton
                    key={`employer-${i}`}
                    component="a"
                    href={data.url}
                  >
                    <FooterListItemText primary={data.label} />
                  </FooterListItemButton>
                ))}
              </List>
              <Typography
                variant="h2"
                sx={{
                  color: "#FFFFFF",
                  fontSize: "16px",
                  fontWeight: "600",
                  marginTop: 2,
                }}
              >
                Our solutions
              </Typography>
              <List dense>
                {dataSolutions.map((data, i) => (
                  <FooterListItemButton
                    key={`solution-${i}`}
                    component="a"
                    href={data.url}
                  >
                    <FooterListItemText primary={data.label} />
                  </FooterListItemButton>
                ))}
              </List>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography
                variant="h2"
                sx={{
                  color: "#FFFFFF",
                  fontSize: "16px",
                  fontWeight: "600",
                }}
              >
                About us
              </Typography>
              <List dense>
                {dataAboutUs.map((data, i) => (
                  <FooterListItemButton
                    key={`about-${i}`}
                    component="a"
                    href={data.url}
                  >
                    <FooterListItemText primary={data.label} />
                  </FooterListItemButton>
                ))}
              </List>
              <Typography
                variant="h2"
                sx={{
                  color: "#FFFFFF",
                  fontSize: "16px",
                  fontWeight: "600",
                  marginTop: 2,
                }}
              >
                Areas of expertise
              </Typography>
              <List dense>
                {dataExpertize.map((data, i) => (
                  <FooterListItemButton
                    key={`expertise-${i}`}
                    component="a"
                    href={data.url}
                  >
                    <FooterListItemText primary={data.label} />
                  </FooterListItemButton>
                ))}
              </List>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <hr />
            </Grid>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="flex-end"
              width="100%"
            >
              <Grid item xs={12} display="flex" gap="16px">
                <a
                  href="https://www.facebook.com/savannahrservices"
                  target="_blank"
                >
                  <img src={fb} alt="fb" />
                </a>
                <a href="https://www.instagram.com/hrsavanna">
                  <img src={insta} alt="insta" />
                </a>
                <a
                  href="https://www.linkedin.com/company/savanna-hr/"
                  target="_blank"
                >
                  <img src={linkedin} alt="linkedin" />
                </a>
                <a href="https://twitter.com/Savanna__HR">
                  <img src={twitter} alt="twitter" />
                </a>
              </Grid>
              <div
                className="review-widget_net"
                data-uuid="9cb716cd-88e7-4d40-929b-bb4d5e4248f3"
                data-template="10"
                data-lang="en"
                data-theme="light"
              ></div>
            </Stack>

            <Grid item xs={12}>
              <Typography
                sx={{ fontSize: "12px", padding: "1em 0px", color: "#FFFFFF" }}
              >
                registered office: Savanna HR India, 90B, Delhi – Jaipur Expy,
                Sector 18 Gurugram Haryana 122008. email: hello@savannahr.com
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" sx={{ color: "#FFFFFF" }}>
                Security Advisory: Savanna HR does not levy any charges at any
                point during its recruitment process. Our company policy
                strictly prohibits employees from collecting fees from candidate
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                sx={{ fontSize: "12px", padding: "1em 0px", color: "#FFFFFF" }}
              >
                Savanna HR @2016-2024 Savanna HR All rights reserved
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  )
}

export default Footer
