import React from "react"
import {
  Box,
  Container,
  FormControl,
  TextField,
  Typography,
  styled,
  Stack,
  Grid,
  Button,
} from "@mui/material"
import { Formik, FormikHandlers, FormikValues } from "formik"
import axios from "axios"

const EMAILER_API = "https://emailer.technocube.co"
const textRegeEx = /^[a-zA-Z\s]+$/
const mobileRegex = /^(((0|((\+)?91(\-)?))|((\((\+)?91\)(\-)?)))?[7-9]\d{9})?$/
const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/

//@ts-ignore
import savannaHRLogo from "../../resources/savana/savhr.png"

const FormTextField = styled(TextField)({
  minWidth: "280px",
  "& .MuiInputBase-root": {
    background: "#FFFFFF",
    borderRadius: "16px 0px 15px 15px",
    fontSize: "16px",
  },
  "& .MuiFormLabel-root": {
    fontSize: "16px",
  },
})

interface FormValues {
  name: string
  phone: string
  email: string
  business: string
  message: string
}

const QueryForm = () => {
  const [ack, setAck] = React.useState("")
  const handleSubmit = (values: FormValues, actions: any) => {
    axios({
      method: "POST",
      baseURL: EMAILER_API,
      url: "/api/savanna-web/email",
      data: values,
    })
      .then(res => {
        setAck("Your query submitted successfully!")
        actions.resetForm()
      })
      .catch(e => {
        setAck("Something Went wrong!")
      })
  }
  return (
    <Box
      sx={{
        backgroundColor: "#F1F5FE",
        backgroundImage: "url('../../resources/icons/dots.svg')",
      }}
      paddingTop="40px"
      paddingBottom="20px"
    >
      <Container maxWidth="xl">
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12} md={6}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Typography
                variant="h2"
                color="primary"
                sx={{
                  fontSize: "34px",
                  fontWeight: "500",
                }}
              >
                Let's talk about your next hiring
              </Typography>
              <Typography color="primary" variant="subtitle2">
                We Streamline your hiring
              </Typography>
              <img alt="SavannaHR Logo" src={savannaHRLogo} />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                textTransform: "uppercase",
                fontSize: "22px",
                fontWeight: "bold",
              }}
            >
              Let's Talk
            </Typography>
            <Formik
              initialValues={{
                name: "",
                phone: "",
                email: "",
                business: "",
                message: "",
              }}
              validate={values => {
                setAck("")
                const errors: any = {}
                if (!values.name) {
                  errors.name = "Your name is required!"
                }
                if (!textRegeEx.test(values.name)) {
                  errors.name = "Please enter a name using characters only!"
                }
                if (!values.phone) {
                  errors.phone = "Phone is required!"
                }
                if (!mobileRegex.test(values.phone)) {
                  errors.phone = "Please enter valid mobile or phone!"
                }
                if (!values.email) {
                  errors.email = "Email is required!"
                }
                if (!emailRegex.test(values.email)) {
                  errors.email = "Please enter valid email!"
                }
                if (!values.business) {
                  errors.business = "Business name is required!"
                }
                if (!values.message) {
                  errors.message = "Message name is required!"
                }
                return errors
              }}
              onSubmit={handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <Box component="form" onSubmit={handleSubmit}>
                  <Stack
                    py={2}
                    gap={2}
                    direction="row"
                    justifyContent="space-between"
                    flexWrap="wrap"
                  >
                    <FormControl
                      size="small"
                      error={Boolean(touched.name && errors.name)}
                    >
                      <FormTextField
                        label="Your Name"
                        size="small"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={errors.name}
                        error={Boolean(touched.name && errors.name)}
                      />
                    </FormControl>
                    <FormControl
                      size="small"
                      error={Boolean(touched.phone && errors.phone)}
                    >
                      <FormTextField
                        label="Phone"
                        size="small"
                        name="phone"
                        value={values.phone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={errors.phone}
                        error={Boolean(touched.phone && errors.phone)}
                      />
                    </FormControl>
                    <FormControl
                      size="small"
                      error={Boolean(touched.email && errors.email)}
                    >
                      <FormTextField
                        label="Work Email"
                        size="small"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={errors.email}
                        error={Boolean(touched.email && errors.email)}
                      />
                    </FormControl>
                    <FormControl
                      size="small"
                      error={Boolean(touched.business && errors.business)}
                    >
                      <FormTextField
                        label="Business Name"
                        size="small"
                        name="business"
                        value={values.business}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={errors.business}
                        error={Boolean(touched.business && errors.business)}
                      />
                    </FormControl>
                  </Stack>
                  <Stack
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <FormControl
                      size="small"
                      fullWidth
                      error={Boolean(touched.message && errors.message)}
                    >
                      <FormTextField
                        label="Your Message"
                        size="small"
                        multiline
                        rows={5}
                        name="message"
                        value={values.message}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={errors.message}
                        error={Boolean(touched.message && errors.message)}
                      />
                    </FormControl>
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "#165ee8",
                      }}
                      type="submit"
                    >
                      Submit
                    </Button>
                    {ack && <Typography>{ack}</Typography>}
                  </Stack>
                </Box>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default QueryForm
